<template v-if="formActive">
    <div class="wrapper" v-if="item.job_booking_voyage && item.job_booking_voyage.length == 0">{{ $t('errors.no_units_for_voyage') }}</div>
    <div id="freight-wrapper" class="wrapper position-relative" v-else>
        <div id="freight-header">
            <div class="row unit-row fw-bold" style="padding-top: 3px">
                <div class="col-auto" style="width: 3%">
                    <FormItem id="upol" v-model="allPorts" type="checkbox" @onchange="(e) => selectAll(e)" />
                </div>
                <div class="col-auto col-form-label-sm sortable" :class="{'selected-header': sorters.customer != 0}" @click="sort('customer')" style="width: 11%">
                    {{ $t('navigation.customers') }} <span :data-sort="sorters.customer">{{ Math.abs(sorters.customer) }}</span>
                </div>
                <div class="col-auto col-form-label-sm sortable" :class="{'selected-header': sorters.chassisnr != 0}" @click="sort('chassisnr')" style="width: 11%">
                    {{ $t('navigation.units') }} <span :data-sort="sorters.chassisnr">{{ Math.abs(sorters.chassisnr) }}</span>
                </div>
                <div class="col-auto col-form-label-sm" :style="{width: item.is_operator ? '5%' : '7%'}">
                    Chk.
                </div>
                <div class="col-auto col-form-label-sm" :style="{width: item.is_operator ? '21%' : '36.6%'}">
                    Booked
                </div>
                <div class="col-auto col-form-label-sm" :style="{width: item.is_operator ? '21%' : '16%'}">
                    Measured
                </div>
                <div class="col-auto col-form-label-sm" v-show="item.is_operator" style="width: 20%">
                    {{ $t('sales.purchase_rates') }}
                </div>
            </div>
        </div>
        <div style="height: 30px"></div>
        <div id="unit-freight-list">
            <template v-for="pol in item.ports" :key="pol.id">
                <div class="unit-header-row unit-row" v-show="pol.pods[0]">
                    <div style="width: 3%"> <FormItem :id="'pol-'+pol.id" v-model="pol.selected" type="checkbox" @onchange="(e) => setChosenPOLs(e, pol)" /> </div>
                    <div class="pt-1 fw-bold" style="width: 22%"> <label :for="'pol-'+pol.id">{{pol.name}}</label> </div>
                    <div class="pt-1 text-center">
                        <i v-show="pol.checked_count == pol.total_count" class="far fa-check-circle text-success"></i> 
                        <span v-show="pol.checked_count != pol.total_count">{{pol.checked_count + ' / ' + pol.total_count}}</span> 
                    </div>
                </div>
                <template v-for="pod in pol.pods" :key="pod.id">
                    <div class="unit-header-row unit-row">
                        <div style="width: 3%"> <FormItem :id="'pol-'+pol.id+'-'+pod.id" v-model="pod.selected" type="checkbox" @onchange="(e) => setChosenPODs(e, pod, pol)" /> </div>
                        <div class="pt-1 fw-bold d-flex" style="width: 22%">
                            <button :id="'btn-'+pol.id+'-'+pod.id" class="btn p-0 shadow-none" type="button" style="position: relative; bottom: 2px" @click="pod.expanded = !pod.expanded">
                            <i class="fa fa-chevron-right" :style="{'rotate': (pod.expanded ? '90deg' : '0deg')}"></i>
                            </button>
                            <label class="col-form-label auto-check-label ps-2 pt-1 pb-0 fw-bold" :for="'pol-'+pol.id+'-'+pod.id">{{pod.name}}</label>
                        </div>
                        <div class="pt-1 text-center">
                            <i v-show="pod.checked_count == pod.total_count" class="far fa-check-circle text-success"></i>
                            <span v-show="pod.checked_count != pod.total_count">{{pod.checked_count + ' / ' + pod.total_count}}</span>
                        </div>
                    </div>
                    <div v-if="filteredUnits[pol.code + '-' + pod.code]" class="collapse" :id="'collapse-' +pol.id + '-' + pod.id" :class="{'show': pod.expanded}">
                    <div class="row unit-row details-row position-relative" v-for="(unit, index) in filteredUnits[pol.code + '-' + pod.code]" :key="unit.id">
                        <div style="width: 3%" class="checkbox-cell" :class="{'checked-unit': item.is_operator ? unit.checked : unit.are_measurements_chosen}">
                            <FormItem :id="'bl-'+unit.id+'-'+pol.id+'-'+pod.id" v-model="unit.selected" :disabled="!item.is_operator && !unit.checked" type="checkbox" @onchange="(e) => setChosenBLs(e, pol, pod, unit)" />
                        </div>
                        <div class="col-auto" style="width: 11%">
                            <div class="overflow-ellipsis">{{ unit.customer }}</div>
                            <div class="overflow-ellipsis">{{ unit.job_number }}</div>
                        </div>
                        <div class="col-auto" :style="{width: item.is_operator ? '11%' : '18%'}">
                            <div class="overflow-ellipsis">{{ unit.commodity ? unit.commodity.name : '-' }}</div>
                            <div class="overflow-ellipsis">{{ unit.makemodel }}</div>
                            <div class="overflow-ellipsis">{{ unit.chassisnr }}</div>
                            <div>
                                <span v-show="(item.is_operator && !unit.checked && unit.are_measurements_chosen) || (!item.is_operator && !unit.checked)" class="unit-label bg-gwarning popover-trigger me-2">
                                    <i class="fal fa-triangle-exclamation"></i>
                                </span>
                                <span class="remark-blurb popover-bubble">
                                    {{item.is_operator ? 'The Sell measurements are selected in the job, but the Buy measurements still need to be checked.' : 'The operator has not selected the Buy measurements yet.'}}
                                </span>
                                <span v-show="(item.is_operator && unit.checked) || (!item.is_operator && unit.are_measurements_chosen)" class="unit-label bg-success me-2"><i class="far fa-check"></i></span>
                                <span v-show="unit.delivered_date" class="unit-label bg-dark me-2">D</span>
                            </div>
                        </div>
                        <div class="col-auto measurements-flex" :style="{width: item.is_operator ? '47%' : '67%'}">
                            
                            <div class="d-flex">
                                <div class="chk">
                                    Buy:
                                </div>
                                <div class="d-flex col">
                                    <div>
                                        <input type="radio" class="form-check-input" :id="'booked-cbmx-'+unit.id" :name="'cbmx-unit-'+unit.id" :value="1" v-model="unit.purchase_measurement_type_id" 
                                              :disabled="!item.is_operator" @change="sendMeasurements(unit, 'purchase_measurement')" />
                                    </div>
                                    <label :for="'booked-cbmx-'+unit.id" :data-advised="!unit.measured_measurement || !unit.measured_booked_higher">
                                        {{ unit.booked_measurement ? (getVolumeRounded(unit.booked_measurement) ) : '-' }} cbm, {{getMeasurements(unit.booked_measurement)}}
                                    </label>
                                </div>
                                <div class="d-flex col">
                                    <div>
                                        <input type="radio" class="form-check-input" :id="'measured-cbmx-'+unit.id" :name="'cbmx-unit-'+unit.id" :value="2" v-model="unit.purchase_measurement_type_id" 
                                              :disabled="!item.is_operator" @change="sendMeasurements(unit, 'purchase_measurement')" />
                                    </div>
                                    <label :for="'measured-cbmx-'+unit.id" :data-advised="unit.measured_measurement && unit.measured_booked_higher">
                                        {{ unit.measured_measurement ? (getVolumeRounded(unit.measured_measurement) ) : '-' }} cbm, {{getMeasurements(unit.measured_measurement)}}
                                    </label>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="chk"></div>
                                <div class="d-flex col">
                                    <div>
                                        <input type="radio" class="form-check-input" :id="'booked-weix-'+unit.id" :name="'weix-unit-'+unit.id" :value="1" v-model="unit.purchase_weight_type_id" 
                                              :disabled="!item.is_operator" @change="sendMeasurements(unit, 'purchase_weight')" />
                                    </div>
                                    <label :for="'booked-weix-'+unit.id" :data-advised="!unit.measured_measurement || !unit.weight_booked_higher">
                                        {{ unit.booked_measurement ? formatNumber(unit.booked_measurement.weight) : '-' }} kg
                                    </label>
                                </div>
                                <div class="d-flex col">
                                    <div>
                                        <input type="radio" class="form-check-input" :id="'measured-weix-'+unit.id" :name="'weix-unit-'+unit.id" :value="2" v-model="unit.purchase_weight_type_id" 
                                              :disabled="!item.is_operator" @change="sendMeasurements(unit, 'purchase_weight')" />
                                    </div>
                                    <label :for="'measured-weix-'+unit.id" :data-advised="unit.measured_measurement && unit.weight_booked_higher">
                                        {{ unit.measured_measurement && unit.measured_measurement.weight ? formatNumber(unit.measured_measurement.weight) : '-' }} kg
                                    </label>
                                </div>
                            </div>
                            <hr class="my-1">
                            <div class="d-flex">
                                <div class="chk">
                                    Sell:
                                </div>
                                <div class="d-flex col">
                                    <div>
                                        <input type="radio" class="form-check-input" :id="'booked-cbm-'+unit.id" :name="'cbm-unit-'+unit.id" :value="1" v-model="unit.unit_measurement_type_id" 
                                              :disabled="!item.is_operator && !unit.checked && !unit.are_measurements_chosen" @change="sendMeasurements(unit, 'unit_measurement')" />
                                    </div>
                                    <label :for="'booked-cbm-'+unit.id" :data-advised="!unit.measured_measurement || unit.measured_booked_higher">
                                        {{ unit.booked_measurement ? (getVolumeRounded(unit.booked_measurement) ) : '-' }} cbm, {{getMeasurements(unit.booked_measurement)}}
                                    </label>
                                </div>
                                <div class="d-flex col">
                                    <div>
                                        <input type="radio" class="form-check-input" :id="'measured-cbm-'+unit.id" :name="'cbm-unit-'+unit.id" :value="2" v-model="unit.unit_measurement_type_id"
                                              :disabled="!item.is_operator && !unit.checked && !unit.are_measurements_chosen" @change="sendMeasurements(unit, 'unit_measurement')" />
                                    </div>
                                    <label :for="'measured-cbm-'+unit.id" :data-advised="unit.measured_measurement && !unit.measured_booked_higher">
                                        {{ unit.measured_measurement ? (getVolumeRounded(unit.measured_measurement) ) : '-' }} cbm, {{getMeasurements(unit.measured_measurement)}}
                                    </label>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="chk"> </div>
                                <div class="d-flex col">
                                    <div>
                                        <input type="radio" class="form-check-input" :id="'booked-wei-'+unit.id" :name="'weight-unit-'+unit.id" :value="1" v-model="unit.unit_weight_type_id" 
                                              :disabled="!item.is_operator && !unit.checked && !unit.are_measurements_chosen" @change="sendMeasurements(unit, 'unit_weight')" />
                                    </div>
                                    <label :for="'booked-wei-'+unit.id" :data-advised="!unit.measured_measurement || unit.weight_booked_higher">
                                        {{ unit.booked_measurement ? formatNumber(unit.booked_measurement.weight) : '-' }} kg
                                    </label>
                                </div>
                                <div class="d-flex col">
                                    <div>
                                        <input type="radio" class="form-check-input" :id="'measured-wei-'+unit.id" :name="'weight-unit-'+unit.id" :value="2" v-model="unit.unit_weight_type_id" 
                                              :disabled="!item.is_operator && !unit.checked && !unit.are_measurements_chosen" @change="sendMeasurements(unit, 'unit_weight')" />
                                    </div>
                                    <label :for="'measured-wei-'+unit.id" :data-advised="unit.measured_measurement && !unit.weight_booked_higher">
                                        {{ unit.measured_measurement && unit.measured_measurement.weight ? formatNumber(unit.measured_measurement.weight) : '-' }} kg
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto" style="width: 27%" v-show="item.is_operator">
                            <div class="d-flex">
                                <div class="pt-1" style="width: 20%">{{$t('sales.freight')}}</div>
                                <FormItem :id="'unit_'+unit.id" type="currency-and-unit" v-model="filteredUnits[pol.code + '-' + pod.code][index]" @onchange="update(unit, true, false)" sizeLabel="2" sizeItem="10"
                                        :options="currencyAndUnitOptions" inputGroupType="freight_purchase_rate" :calcUnitFields="calcUnitFields" class="px-1" />
                                <button class="btn mini-button bg-success px-2" type="button" :disabled="loading" @click="addSurcharge(unit)">
                                    <i class="fa fa-plus text-white"></i>
                                </button>
                            </div>
                            <div v-for="(surcharge, sIndex) in unit.job_booking_voyage_job_unit_surcharge" :key="sIndex" class="d-flex mt-2">
                                <FormItem v-model="surcharge.surcharge_type_id" type="select" :options="surchargeTypes" :extraOption="unit" nameField="code" :disabledOptions="checkIfAlreadyChosen" 
                                        @onchange="update(unit); setCurrencySuggestions()" style="width: 20%" content="narrow" />
                                <FormItem v-model="unit.job_booking_voyage_job_unit_surcharge[sIndex]" inputGroupType="purchase_rate" :calcUnitFields="calcUnitFields" class="px-1" sizeLabel="2" sizeItem="10"
                                        :id="'u_'+unit.id + '_'+sIndex" type="currency-and-unit" :options="currencyAndUnitOptions" @onchange="update(unit, true); setCurrencySuggestions()" />
                                <button class="btn mini-button bg-danger px-2" type="button" :disabled="loading" @click="removeSurcharge(unit, sIndex)">
                                    <i class="fa fa-trash-alt text-white"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
    <div id="freight-footer">
        <div class="d-flex gap-2" style="align-items: center">
            <button type="button" class="btn btn-sm btn-light" @click="filter('checked')">
                <span class="unit-label bg-success"><i class="far fa-check"></i></span>&nbsp;Checked<i :class="checkFilter(filterOptions.checked)"></i>
            </button>
            <button type="button" class="btn btn-sm btn-light" @click="filter('delivered')">
                <span class="unit-label bg-dark">D</span>&nbsp;{{$t('operations.date_delivered')}}<i :class="checkFilter(filterOptions.delivered)"></i>
            </button>
            <span class="pt-1">|</span>
            <FormItem type="text" placeholder="Search" v-model="filterOptions.search" @onchange="filter()" />
            <span class="pt-1">|</span>
            <div class="col-form-label-sm text-muted">
                <div v-show="checkedUnits != totalUnits">{{totalUnits - checkedUnits}} units are not checked</div>
                <div v-if="!item.project" v-show="item.is_operator">
                    This voyage has no project. 
                    <a :href="'#' + item.id" @click="openER"> Click here to add</a>
                </div>
                <div v-else-if="currencySuggestions.length > 0" v-show="item.is_operator">
                    Project {{item.project.number}} has missing exchange rates. 
                    <a :href="'#' + item.id" @click="openER"> Click here to add</a>
                </div>
                <div v-else>Project number: {{item.project.number}}</div>
            </div>
        </div>
        <div class="d-flex gap-2">
            <button id="bulkedit" type="button" class="btn btn-primary" @click.prevent="openBulkEdit"><i class="fa fa-edit"></i>&ensp;Bulk edit</button>
            <button id="checkbutton" type="button" class="btn btn-success" @click.prevent="checkUnits" v-show="showCheckButton"><i class="fa fa-check"></i>&ensp;Check</button>
            <FormItem type="download" label="Generate breakdown" v-if="item.is_operator" :disabled="isBreakdownDisabled" :service="generateBreakdown" />
        </div>
    </div>
    <dialog id="bulk-dialog">
        <form method="dialog">
            <div class="modal-content">
                <div class="modal-header w-100 pt-3 pb-2">
                    <h5 style="font-size: 1.2rem !important" class="ps-2">Bulk edit</h5>
                    <button id="fakeCloseBtn2" class="btn-close btn-black" aria-label="Close"></button>
                </div>
                <div class="p-4">
                    <FormItem id="fpx-1" type="livesearch" v-model="bulkEditOptions.pol_id" :service="portservice" :label="$t('sales.loading_ports')" :content="polName" />
                    <FormItem id="fpx-2" type="livesearch" v-model="bulkEditOptions.pod_id" :service="portservice" :label="$t('sales.discharge_ports')" :content="podName" />
                    <FormItem type="select" v-model="bulkEditOptions.commodity_id" :options="commodities" :label="$t('navigation.commodities')" placeholder="All" />
                    <template v-if="item.is_operator">
                        <FormItem type="radiogroup" id="bulkmes-1" v-model="bulkEditOptions.purchase_measurements_id" :options="measurementIds" label="Purchase CBM" />
                        <FormItem type="radiogroup" id="bulkmes-2" v-model="bulkEditOptions.purchase_weights_id" :options="measurementIds" label="Purchase Weight" />
                    </template>
                    <FormItem type="radiogroup" id="bulkmes-3" v-model="bulkEditOptions.sales_measurements_id" :options="measurementIds" label="Sales CBM" />
                    <FormItem type="radiogroup" id="bulkmes-4" v-model="bulkEditOptions.sales_weights_id" :options="measurementIds" label="Sales Weight" />
                    <template v-if="item.is_operator">
                        <FormItem id="bulk-freight" type="currency-and-unit" v-model="bulkEditOptions" :label="$t('operations.freight')"
                                    :options="currencyAndUnitOptions" inputGroupType="freight" :calcUnitFields="calcUnitFields" />
                        <div class="d-flex gap-3">
                            <div class="fw-bold">{{$tc('navigation.surcharges', 2)}}</div>
                            <button class="btn mini-button bg-success px-2" type="button" @click="addSurcharge(null)">
                                <i class="fa fa-plus text-white"></i>
                             </button>
                        </div>
                        <div v-for="(surcharge, sIndex) in bulkEditOptions.surcharges" :key="sIndex" class="d-flex gap-1 mt-2">
                            <FormItem v-model="surcharge.surcharge_type_id" type="select" :options="surchargeTypes" nameField="code" class="col-4" content="narrow" />
                            <FormItem v-model="bulkEditOptions.surcharges[sIndex]" inputGroupType="purchase_rate" :calcUnitFields="calcUnitFields" class="col-7 px-1" sizeLabel="2" sizeItem="10"
                                    :id="'u_x_'+sIndex" type="currency-and-unit" :options="currencyAndUnitOptions" />
                            <div class="col text-end">
                                <button class="btn mini-button bg-danger px-2" type="button" @click="removeSurcharge(null, sIndex)">
                                    <i class="fa fa-trash-alt text-white"></i>
                                </button>
                            </div>
                        </div>
                    </template>
                    <button class="btn btn-success mt-4 px-3" @click="setBulkEdit">{{ $t('form.save') }}</button>
                </div>
            </div>
        </form>
    </dialog>
    <dialog id="er-dialog">
        <form method="dialog">
            <div class="modal-content">
                <div class="modal-header w-100 pt-3 pb-2">
                    <h5 style="font-size: 1.2rem !important" class="ps-2">Exchange rate</h5>
                    <button id="fakeCloseBtn3" class="btn-close btn-black" aria-label="Close" @click.prevent="closeER"></button>
                </div>
                <div class="p-4">
                    <MainportForm :itemData="{}" :fromFreightlist="true" :currencySuggestions="currencySuggestions" ref="mainportform" />
                    <button class="btn btn-success mt-4 px-3" @click="setExchangeRate">{{ $t('form.save') }}</button>
                </div>
            </div>
        </form>
    </dialog>
</template>

<script>
import freightlistService from '@/services/FreightlistService';
import currencyService from "@/services/CurrencyService";
import calculationunitService from "@/services/CalculationunitService";
import surchargetypeService from "@/services/SurchargetypeService";
import portService from '@/services/PortService';
import mainportService from '@/services/MainportProjectService';
import MainportForm from '@/components/legacy/MainportForm.vue';

export default {
    data() {
        return {
            item: {},
            filteredUnits: {},
            surchargeTypes: [],
            commodities: [],
            currencySuggestions: [],
            selectedPols: [],
            selectedPods: [],
            selectedUnits: [],
            currencyAndUnitOptions: { 'currencies': [], 'calculation_units': []},
            calcUnitFields: ['currency_id', 'calculation_unit_id'],
            portservice: portService,
            bulkEditOptions: {
                pol_id: null,
                pod_id: null,
                commodity_id: null,
                freight_currency_id: null,
                freight_calculation_unit_id: null,
                surcharges: [],
                purchase_measurements_id: null,
                purchase_weights_id: null,
                sales_measurements_id: null,
                sales_weights_id: null
            },
            polpodCombinations: [],
            filterOptions: {},
            sorters: {'job_number': 0, 'customer': 0, 'chassisnr': 0, 'sort_date': 0},
            activeSortRows: [],
            measurementIds: [{id: null, name: "Don't specify"}, {id: 1, name: 'Booked'}, {id: 2, name: 'Measured'}],
            totalUnits: 0,
            checkedUnits: 0,
            erSpecified: null,
            nextSortOrder: 1,
            showCheckButton: false,
            hasUncheckedUnits: false,
            polName: '',
            podName: '',
            surchargeTypeAmount: 0,
            submitted: false,
            loading: false,
            loadingVoyage: false,
            invalid: {},
            dataItemsLoaded: 0,
            maxDataItemsToload: 2,
            formActive: false
        }
    },
    components: {
        MainportForm
    },
    computed: {
        isBreakdownDisabled(){
            return !this.item.project || this.currencySuggestions.length > 0 || this.hasUncheckedUnits;
        }
    },
    methods: {
        active(value){
            this.formActive = value;
        },
        validate(){
            return true;
        },
        getData() {
            return this.item;
        },
        setData(data, isUpdate = false) {
            this.item = data;
            console.log(data);
            this.loadingVoyage = false;
            this.dataItemsLoaded = 0;
            document.getElementById('detailsSpan').innerHTML = ' - ' + data.name + ', ' + (data.carrier ? data.carrier.relation.name : '');
            this.filteredUnits = {};
            this.checkedUnits = 0;
            const commodityIds = [];
            this.commodities = [];
            this.currencySuggestions = [];
            this.totalUnits = 0;
            this.polpodCombinations = [];
            this.item.ports.forEach(pol => { 
                pol.pods.forEach(pod => {
                    this.filteredUnits[pol.code + '-' + pod.code] = [...pod.units];
                    this.polpodCombinations.push(pol.code + '-' + pod.code);
                    pod.units.forEach(unit => {
                        if(unit.commodity && !commodityIds.includes(unit.commodity.id)){
                            commodityIds.push(unit.commodity.id);
                            this.commodities.push({
                                id: unit.commodity.id,
                                name: unit.commodity.name
                            });
                        }
                        this.totalUnits++;
                        if(unit.checked){
                            this.checkedUnits++;
                        }
                    });
                }); 
            });
            delete this.item.job_booking_voyage;
            this.setCurrencySuggestions();
            if(!isUpdate){
                this.loadData();
            }
        },
        reset() {
            this.loading = false;
            this.loadingVoyage = true;
            this.totalUnits = 0;
            this.checkedUnits = 0;
            this.filterOptions = {
                pol_id: null,
                pod_id: null,
                commodity_id: null,
                checked: null,
                delivered: null,
                search: ''
            };
            this.polName = '';
            this.podName = '';
            this.showCheckButton = false;
            this.hasUncheckedUnits = false;
        },
        update(unit, checkCurrencies = false, updateSurcharges = true){
            if(this.loading) return;
            this.loading = true;
            let item = {
                id: unit.id,
                freight_purchase_rate: unit.freight_purchase_rate,
                freight_purchase_rate_calculation_unit_id: unit.freight_purchase_rate_calculation_unit_id,
                freight_purchase_rate_currency_id: unit.freight_purchase_rate_currency_id,
                job_booking_voyage_job_unit_surcharge: updateSurcharges ? unit.job_booking_voyage_job_unit_surcharge : [],
                is_checked: false
            };
            unit.checked = false;
            this.varUpdate();
            freightlistService.update(item).then((response) => {
                console.log('freightlist update', response.data);
                const size = unit.job_booking_voyage_job_unit_surcharge.length;
                const deleteIndexes = [];
                for(let i = 0; i < size; i++){
                    if(!unit.job_booking_voyage_job_unit_surcharge[i].id){
                        unit.job_booking_voyage_job_unit_surcharge[i].id = response.data.job_booking_voyage_job_unit_surcharge[i].id;
                    }
                    else if(unit.job_booking_voyage_job_unit_surcharge[i].deleted){
                        deleteIndexes.push(i);
                    }
                }
                deleteIndexes.forEach(index => {
                    unit.job_booking_voyage_job_unit_surcharge.splice(index, 1);
                });
                this.loading = false;
                if(checkCurrencies){
                    this.setCurrencySuggestions();
                }
            }).catch(error => {
                this.toastError(error)
                console.log('error', error)
                this.loading = false
            });
        },
        setCurrencySuggestions(){
            this.currencySuggestions = [];
            this.projectCurrencies = [];
            let defaultCurrency = this.item.project ? this.item.project.default_currency_id	: 2;
            if(this.item.project && this.item.project.mainport_project_exchange_rates.length > 0){
                this.item.project.mainport_project_exchange_rates.forEach(rate => this.projectCurrencies.push(rate.currency_id));
            }
            this.item.ports.forEach(pol => {
                pol.pods.forEach(pod => {
                    pod.units.forEach(unit => {
                        let prate = unit.freight_purchase_rate_currency_id;
                        if(prate && prate != defaultCurrency && !this.currencySuggestions.includes(prate) && !this.projectCurrencies.includes(prate)){
                            this.currencySuggestions.push(prate);
                        }
                        unit.job_booking_voyage_job_unit_surcharge.forEach(surcharge => {
                            let srate = surcharge.purchase_rate_currency_id;
                            if(srate && srate != defaultCurrency && !this.currencySuggestions.includes(srate) && !this.projectCurrencies.includes(srate)){
                                this.currencySuggestions.push(srate);
                            }
                        });
                    });
                });
            });
            this.varUpdate();
        },
        getCurrencies() {
            currencyService.index().then(response => {
                this.currencyAndUnitOptions.currencies = response.data;
                window.defaultCurrency = this.currencyAndUnitOptions.currencies.filter(currency => currency.is_default == 1)[0].id;
                this.dataItemsLoaded++;
                if(this.dataItemsLoaded == this.maxDataItemsToload){
                    this.processData();
                }
            }).catch(error => {
                console.log('error', error)
            })
        },
        getCalculationunits() {
            calculationunitService.index().then(response => {
                this.currencyAndUnitOptions.calculation_units = response.data.filter(cu => cu.id < 6);
                this.dataItemsLoaded++;
                if(this.dataItemsLoaded == this.maxDataItemsToload){
                    this.processData();
                }
            }).catch(error => {
                console.log('error', error)
            })
        },
        getSurchargeTypes(){
                surchargetypeService.index(null, null, 'surchargetype').then(response => {
                    this.surchargeTypes = response.data //todo
                    this.surchargeTypeAmount = response.data.length;
                }).catch(error => {
                    console.log('error', error)
                })
            },
        loadData () {
            this.getCurrencies()
            this.getCalculationunits()
            this.getSurchargeTypes()
        },
        processData(){
            this.filteredUnits = {};
            this.item.ports.forEach(pol => {
                pol.pods.forEach(pod => {
                    pod.units.forEach(unit => {
                        const defaultMeasurementsId = unit.measured_measurement && unit.measured_measurement.width ? 2 : 1;
                        if(unit.freight_purchase_rate_currency_id === null)
                            unit.freight_purchase_rate_currency_id = window.defaultCurrency;
                        if(unit.freight_purchase_rate_calculation_unit_id === null)
                            unit.freight_purchase_rate_calculation_unit_id = 1;
                        if(this.item.is_operator){
                            if(!unit.unit_measurement_type_id)
                                unit.unit_measurement_type_id = defaultMeasurementsId;
                            if(!unit.unit_weight_type_id)
                                unit.unit_weight_type_id = defaultMeasurementsId;
                            if(!unit.purchase_measurement_type_id)
                                unit.purchase_measurement_type_id = defaultMeasurementsId;
                            if(!unit.purchase_weight_type_id)
                                unit.purchase_weight_type_id = defaultMeasurementsId;
                        }
                        else{
                            if(unit.purchase_measurement_type_id){
                                unit.purchase_measurement_type_id = unit.unit_measurement_type_id_operator == 2 ? 2 : 1;
                                unit.purchase_weight_type_id = unit.unit_weight_type_id_operator == 2 ? 2 : 1;
                            }
                            if(!unit.are_measurements_chosen){
                                unit.unit_measurement_type_id = null;
                                unit.unit_weight_type_id = null;
                            }
                        }
                    });
                    this.filteredUnits[pol.code + '-' + pod.code] = [...pod.units];
                });
            });
            this.varUpdate();
        },
        varUpdate(){
            this.hasUncheckedUnits = false;
            this.checkedUnits = 0;
            this.item.ports.forEach(pol => {
                pol.checked_count = 0;
                pol.pods.forEach(pod => {
                    pod.checked_count = 0;
                    pod.units.forEach(unit => {
                        if(this.item.is_operator){
                            if(!unit.checked)
                                this.hasUncheckedUnits = true;
                            else{
                                this.checkedUnits++;
                                pol.checked_count++;
                                pod.checked_count++;
                            }
                        }
                        else if(unit.are_measurements_chosen){
                            pol.checked_count++;
                            pod.checked_count++;
                        }
                    });
                });
            });
        },
        addSurcharge(unit){
            if(!unit){
                this.bulkEditOptions.surcharges.push({
                    "surcharge_type_id": null,
                    "purchase_rate": '',
                    "purchase_rate_currency_id": window.defaultCurrency,
                    "purchase_rate_calculation_unit_id": 1
                });
                return;
            }
            unit.job_booking_voyage_job_unit_surcharge.push({
                "job_booking_voyage_job_unit_id": unit.id,
                "surcharge_type_id": null,
                "commodity_id": unit.commodity.id,
                "purchase_rate": '',
                "purchase_rate_currency_id": window.defaultCurrency,
                "purchase_rate_calculation_unit_id": 1,
                "deleted": false
            });
            this.update(unit);
        },
        removeSurcharge(unit, index){
            if(!unit){
                this.bulkEditOptions.surcharges.splice(index, 1);
                return;
            }
            unit.job_booking_voyage_job_unit_surcharge[index].deleted = true;
            this.update(unit);
        },
        checkIfAlreadyChosen(item, value, unit){
            if(item.id == value) return false;
            let comms = [];
            unit.job_booking_voyage_job_unit_surcharge.forEach(tsc => {
                comms.push(tsc.surcharge_type_id);
            })
            if(comms.includes(item.id)){
                return true;
            }
            return false;
        },
        getVolumeRounded(rvolume){
            if(!rvolume || !rvolume.width) return '-';
            let volume = (rvolume.length * rvolume.width * rvolume.height) / 1000000;
            if(isNaN(volume)) return "-"; 
            return volume.toFixed(3).replace('.', ',');
        },
        getMeasurements(ms){
            if(!ms || !ms.width) return 'Not specified';
            return ms.length + 'x' +  ms.width + 'x' + ms.height;
        },
        sort(column){
            const order = this.sorters[column];
            if(order === 0){
                this.sorters[column] = this.nextSortOrder;
                this.nextSortOrder++;
            }
            else if(order > 0){
                this.sorters[column] = order * -1;
            }
            else{
                const originalValue = Math.abs(order);
                this.sorters[column] = 0;
                this.nextSortOrder--;
                for(let sorter in this.sorters){
                    if(this.sorters[sorter] != 0 && Math.abs(this.sorters[sorter]) > originalValue){
                        this.sorters[sorter] = this.sorters[sorter] > 0 ? (this.sorters[sorter] - 1) : (this.sorters[sorter] + 1) ;
                    }
                }
            }
            this.activeSortRows = [];
            for(let sorter in this.sorters){
                if(this.sorters[sorter] != 0){
                    this.activeSortRows.splice(Math.abs(this.sorters[sorter]) - 1, 0, {name: sorter, order: this.sorters[sorter]});
                }
            }
            if(this.activeSortRows.length == 0){
                this.activeSortRows = [{name: 'id', order: 1}];
            }
            const sortLength = this.activeSortRows.length;
            for(let polpod in this.filteredUnits){
                    this.filteredUnits[polpod].sort((a, b) => {
                    let result = 0;
                    let i = 0;
                    while(result == 0 && i < sortLength){
                        let field = this.activeSortRows[i].name;
                        let order = this.activeSortRows[i].order > 0 ? 1 : -1;
                        if(a[field] > b[field]){
                            result = order
                        }
                        else if(a[field] < b[field]){
                            result = -order
                        }
                        i++;
                    }
                    return result;
                });
            }
        },
        filter(field, nameValue){
            if(nameValue) this[field + 'Name'] = nameValue;
            if(field == 'checked')
                this.filterOptions.checked = this.filterOptions.checked === null ? true : (this.filterOptions.checked === true ? false : null);
            else if(field == 'delivered')
                this.filterOptions.delivered = this.filterOptions.delivered === null ? true : (this.filterOptions.delivered === true ? false : null);
            const regex = this.filterOptions.search ? new RegExp(this.filterOptions.search, 'i') : null;
            this.item.ports.forEach(pol => {
                pol.pods.forEach(pod => {
                    this.filteredUnits[pol.code + '-' + pod.code] = pod.units.filter(voyage => {
                        if(this.filterOptions.commodity_id && this.filterOptions.commodity_id != voyage.commodity.id) return false;
                        if((this.filterOptions.checked && !voyage.checked) || (this.filterOptions.checked === false && voyage.checked)) return false;
                        if((this.filterOptions.delivered && !voyage.delivered_date) || (this.filterOptions.delivered === false && voyage.delivered_date)) return false;
                        const regexValue = `${voyage.job_number} ${voyage.bl_number} ${voyage.customer} ${voyage.makemodel} ${voyage.chassisnr} ${voyage.commodity.name}`;
                        if(regex && !regex.test(regexValue)) return false;
                        return true;
                    });
                    if(this.filteredUnits[pol.code + '-' + pod.code].length > 0){
                        pod.expanded = 1;
                    }
                });
            });
        },
        checkFilter(value){
            return 'fa ps-2 pe-1 ' + (value === null ? 'fa-check invisible' : (value ? 'fa-check text-success' : 'fa-close text-danger'));
        },
        selectAll(e, newStatus = false){
            this.setChosenPOLPODs();
            const newCheckedStatus = (e === undefined ? newStatus : e.target.checked) ? 1 : 0;
            this.selectedUnits = [];
            this.item.ports.forEach(pol => {
                pol.selected = newCheckedStatus;
                pol.pods.forEach(pod => {
                    pod.selected = newCheckedStatus;
                    pod.units.forEach(unit => {
                        unit.selected = this.item.is_operator || unit.checked ? newCheckedStatus : 0;
                        if(unit.selected) this.selectedUnits.push(unit.id);
                    });
                });
            });
            this.showCheckButton = this.selectedUnits.length > 0;
        },
        setChosenPOLs(e, port){
            this.setChosenPOLPODs();
            const selected = this.selectedPols;
            const otherChosen = this.selectedPods;
            this.allPorts = 0;
            if(e.target.checked){
                if(!selected.includes(port.id)) selected.push(port.id);
                port.hasOne = true;
                port.pods.forEach(pod => {
                    if(!pod.selected){
                        pod.selected = 1;
                    }
                    pod.units.forEach(bl => {
                        if(!bl.selected){
                            bl.selected = this.item.is_operator || bl.checked ? 1 : 0;
                            this.selectedUnits.push(bl.id);
                        }
                    });
                });
            }
            else{
                port.hasOne = false;
                const index = selected.findIndex(ch => ch == port.id);
                if(index !== -1) {
                    selected.splice(index, 1);
                }
                port.pods.forEach(pod => {
                    if(pod.selected){
                        const index2 = otherChosen.findIndex(ch => ch == pod.id);
                        if(index2 !== -1) {
                            otherChosen.splice(index2, 1);
                            pod.selected = 0;
                        }
                    }
                    pod.units.forEach(bl => {
                        if(bl.selected){
                            const index3 = this.selectedUnits.findIndex(ch => ch == bl.id);
                            if(index3 !== -1) {
                                this.selectedUnits.splice(index3, 1);
                                bl.selected = 0;
                            }
                        }
                    });
                });
            }
            this.setChosenPOLPODs();
            this.showCheckButton = this.selectedUnits.length > 0;
        },

        setChosenPODs(e, port, upperPol){
            this.setChosenPOLPODs();
            const selected = this.selectedPods;
            const otherChosen = this.selectedPols;
            const polAbove = upperPol.id;
            this.allPorts = 0;
            if(e.target.checked){
                upperPol.selected = upperPol.pods.find(pod => !pod.selected) ? 0 : 1;
                if(!upperPol.hasOne){
                    upperPol.hasOne = true;
                }
                port.units.forEach(bl => {
                    if(!bl.selected){
                        bl.selected = this.item.is_operator || bl.checked ? 1 : 0;
                        this.selectedUnits.push(bl.id);
                    }
                });
            }
            else{
                const index = selected.findIndex(ch => ch == port.id);
                if(index !== -1) {
                    selected.splice(index, 1);
                }
                if(index !== -1 || selected.length == 0){
                    port.units.forEach(bl => {
                        if(bl.selected){
                            const index2 = this.selectedUnits.findIndex(ch => ch == bl.id);
                            if(index2 !== -1) {
                                this.selectedUnits.splice(index2, 1);
                                bl.selected = 0;
                            }
                        }
                    });
                }
                let allPODsUnselected = true;
                for(let port of upperPol.pods){
                    if(port.selected){
                        allPODsUnselected = false;
                        break;
                    }
                }
                if(allPODsUnselected){
                    const index = otherChosen.findIndex(ch => ch == polAbove);
                    if(index !== -1) {
                        otherChosen.splice(index, 1);
                    }
                }
                upperPol.selected = 0;
                upperPol.hasOne = upperPol.pods.find(pod => pod.selected) ? 1 : 0;
            }
            this.showCheckButton = this.selectedUnits.length > 0;
        },

        setChosenBLs(e, upperPol, upperPod, bl){
            this.setChosenPOLPODs();
            const selected = this.selectedUnits;
            const selectedAbove = this.selectedPods;
            const selectedTop = this.selectedPols;
            const podAbove = upperPod.id;
            const polAbove = upperPol.id;
            this.allPorts = 0;
            if(e.target.checked){
                selected.push(bl.id);
                upperPod.selected = upperPod.units.find(bl => !bl.selected) ? 0 : 1;
                if(!upperPod.selected){
                    upperPod.hasOne = true;
                }
                upperPol.selected = upperPol.pods.find(pod => !pod.selected) ? 0 : 1;
                if(!upperPol.hasOne){
                    upperPol.hasOne = true;
                }
            }
            else{
                const index = selected.findIndex(ch => ch == bl.id);
                if(index !== -1){
                    selected.splice(index, 1);
                    let allBLsUnselected = true;
                    for(let otherBL of upperPod.units){
                        if(otherBL.selected){
                            allBLsUnselected = false;
                            break;
                        }
                    }
                    if(allBLsUnselected){
                    const index = selectedAbove.findIndex(ch => ch == podAbove);
                    if(index !== -1) {
                        selectedAbove.splice(index, 1);
                        let allPODsUnselected = true;
                        for(let port of upperPol.pods){
                            if(port.selected){
                                allPODsUnselected = false;
                                break;
                            }
                        }
                        if(allPODsUnselected){
                            const index2 = selectedTop.findIndex(ch => ch == polAbove);
                            if(index2 !== -1)
                                selectedTop.splice(index2, 1);
                            }
                        }
                    }
                }
                upperPod.selected = 0;
                upperPol.selected = 0;
                upperPol.hasOne = upperPol.pods.find(pod => pod.selected) ? 1 : 0;
            }
            this.showCheckButton = this.selectedUnits.length > 0;
        },
        setChosenPOLPODs(){
            const polObj = {}, podObj = {};
            const ports = this.item.ports;
            ports.forEach(pol => {
                pol.pods.forEach(pod => {
                    pod.units.forEach(unit => {
                        if(unit.selected){
                            polObj[pol.id] = 1;
                            podObj[pod.id] = 1;
                        }
                    });
                });
            });
            this.selectedPols = Object.keys(polObj);
            this.selectedPods = Object.keys(podObj);
        },
        checkUnits(){
            const checkedUnits = [];
            this.polpodCombinations.forEach(polpod => {
                const splitted = polpod.split('-');
                const pol = this.item.ports.find(port => port.code == splitted[0]);
                const pod = pol.pods.find(port => port.code == splitted[1]);
                this.filteredUnits[polpod].forEach(unit => {
                    if(unit.selected){
                        if(this.item.is_operator){
                            unit.checked = true;
                        }
                        else{
                            unit.are_measurements_chosen = true;
                        }
                        pol.checked_count++;
                        pod.checked_count++;
                        checkedUnits.push(unit.id);
                    }
                    unit.selected = false;
                });
            });
            this.varUpdate();
            this.showCheckButton = false;
            this.selectAll(undefined, false);
            document.getElementById('checkbutton').style.display = 'none';
            freightlistService.updateCheckedStatus(checkedUnits, this.item.is_operator).then((response) => {
                console.log('check status update', response.data);
            }).catch(error => {
                this.toastError(error)
                console.log('error', error)
            });
        },
        generateBreakdown(){
            return freightlistService.downloadFreightBreakdown({voyage_id: this.item.id, project_id: this.item.project ? this.item.project.id : null}).then((response) => {
                this.triggerDownload(response.data, `Freight_Breakdown_${this.item.number}.xlsx`);
            }).catch(error => {
                this.toastError(error)
                console.log('error', error)
            });
        },
        sendMeasurements(unit, type){
            let fieldvalue = unit[type + '_type_id'];
            if(this.item.is_operator){
                unit.checked = false;
            }
            else{
                unit.are_measurements_chosen = false;
            }
            this.varUpdate();
            freightlistService.updateMeasurements(unit.id, fieldvalue, type, this.item.is_operator).then((response) => {
                console.log('measurements update', response.data);
            }).catch(error => {
                this.toastError(error)
                console.log('error', error)
            });
        },
        openBulkEdit(){
            document.getElementById('bulk-dialog').show();
            if(!this.filterOptions.pol_id) document.getElementById('fpx-1_wrapper').children[0].click();
            if(!this.filterOptions.pod_id) document.getElementById('fpx-2_wrapper').children[0].click();
            this.bulkEditOptions = {
                pol_id: this.filterOptions.pol_id ? (this.filterOptions.pol_id + 1 - 1) : null,
                pod_id: this.filterOptions.pod_id ? (this.filterOptions.pod_id + 1 - 1) : null,
                commodity_id: this.filterOptions.commodity_id ? (this.filterOptions.commodity_id + 1 - 1) : null,
                freight: null,
                freight_currency_id: window.defaultCurrency,
                freight_calculation_unit_id: 1,
                surcharges: [],
                purchase_measurements_id: null,
                purchase_weights_id: null,
                sales_measurements_id: null,
                sales_weights_id: null
            };
        },
        setBulkEdit(){
            freightlistService.bulkEdit(this.item.id, this.bulkEditOptions)
            .then((response) => {
                this.setData(response.data, true);
                this.processData();
            })
            .catch(error => {
                this.toastError(error)
                console.log('error', error)
            });
        },
        openER(){
            document.getElementById('er-dialog').show();
            this.$refs.mainportform.formActive = false;
            this.$refs.mainportform.reset();
            if(this.item.project){
                this.$refs.mainportform.setOnlyExchangeRates = true;
                mainportService.show(this.item.project.id).then((response) => {
                    this.$refs.mainportform.formActive = true;
                    this.$refs.mainportform.setData(response.data);
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error)
                });
            }
            else{
                this.$refs.mainportform.formActive = true;
                this.$refs.mainportform.mainport.voyage_id = this.item.id;
                this.$refs.mainportform.mainport.voyage = {...this.item};
            }
        },
        closeER(){
            document.getElementById('er-dialog').close();
        },
        setExchangeRate(){
            const item = this.$refs.mainportform.mainport;
            if(item.id){
                mainportService.update(item).then((response) => {
                    this.item.project.mainport_project_exchange_rates = response.data.mainport_project_exchange_rates;
                    this.showBreakdownButton = (this.currencySuggestions.length == 0 || response.data.mainport_project_exchange_rates.length) && this.totalUnits == this.checkedUnits;
                    this.setCurrencySuggestions();
                })
                .catch(error => {
                    this.toastError(error)
                    console.log('error', error)
                });
            }
            else{
                mainportService.store(item).then((response) => {
                    this.item.project = response.data;
                    this.showBreakdownButton = (this.currencySuggestions.length == 0 || response.data.mainport_project_exchange_rates.length) && this.totalUnits == this.checkedUnits;
                    this.setCurrencySuggestions();
                })
                .catch(error => {
                    this.toastError(error)
                    console.log('error', error)
                });
            }
        }
    },
}
</script>
<style scoped>
#freight-wrapper .row{
    margin: unset !important;
}
.unit-row{
    font-size: .85rem;
}
.unit-row:not(:last-child), .unit-row.details-row{
    border-bottom: 1px solid #ddd
}
.unit-row > div:not(.mini-button-column){
    padding: 4px 0 4px 6px !important
}
.unit-header-row{
    height: 30px;
    align-items: center;
    display: flex;
}
.mini-button-column{
    padding: 0 0 0 4px !important
}
.overflow-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
.surcharge-list > div{
    margin-bottom: .25rem
}
.mini-button{
    padding: 2px 0;
    height: 28px
}
.float-bottom{
    position: absolute;
    bottom: 4px
}
.measurements-flex input{
    margin-right: .5rem
}
.border-left{
    border-left: 1px solid lightgray
}
input[type="radio"] + label{
    color: gray;
    padding-left: .5rem
}
.surch-row:not(:first-child){
    margin-top: 1.5rem
}
.surch-row:not(:last-child) > div > button.bg-success{
    visibility: hidden;
}
.remark-blurb{
    display: none;
    position: absolute;
    text-align: center;
    z-index: 1111;
    background-color: #333;
    color: white;
    border-radius: 3px;
    padding: 4px;
    max-width: 250px;
    font-size: 0.8rem;
    font-family: unset
}
i:hover + .remark-blurb{
    display: block !important;
}
.unit-table-sp tr:hover, .sortable:hover{
  background-color: #eee
}
.sortable:hover{
  cursor: pointer
}
#freight-header{
    position: fixed;
    width: 1397px;
    padding-right: 17px;
    background-color: white;
    border-bottom: 1px solid #ddd;
}
#unit-freight-list{
    overflow-y: scroll;
    height: calc(100vh - 190px);
}
#freight-footer{
    height: 50px;
    padding: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-top: 1px solid #ddd;
}
#bulk-dialog, #er-dialog{
    background-color: rgba(1, 1, 1, 0.3) !important;
    border: none;
    background: none;
    padding: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
}
#bulk-dialog > form, #er-dialog > form{
    margin: 1.75rem auto;
    height: calc(100% - 3.5rem);
    min-height: calc(100% - 3.5rem);
    display: flex;
    align-items: center;
    max-width: 600px
}
#er-dialog > form{
    max-width: 800px !important
}
.selected-unit, .selected-header{
    background-color: #cef !important
}
.checked-unit:not(.selected-unit){
    background-color: #d5e9c0 !important
}
.unit-label{
  color: white;
  border-radius: 4px;
  display: inline-block;
  width: 16px;
  text-align: center;
  font-size: 0.75rem;
  margin-right: 2px;
}
label[data-advised="true"]::after, label[data-advised="1"]::after{
    content: "●";
    color: #00dd00;
    margin-left: 4px;
}
.bg-gwarning{
    background-color: #F90 !important;
}
.popover-bubble{
    max-width: 290px !important;
    translate: -140px calc(-20px - 100%) !important;
}
.popover-trigger:hover{ cursor: help }
.popover-trigger:hover+.popover-bubble{ display: block !important }

.chk{
    width: 10%
}
[data-sort="0"]{
  display: none
}
[data-sort="1"]::before, [data-sort="2"]::before, [data-sort="3"]::before, [data-sort="4"]::before, [data-sort="5"]::before, [data-sort="6"]::before, [data-sort="7"]::before, [data-sort="8"]::before{
  content: "\2193"
}
[data-sort="-1"]::before, [data-sort="-2"]::before, [data-sort="-3"]::before, [data-sort="-4"]::before, [data-sort="-5"]::before, [data-sort="-6"]::before, [data-sort="-7"]::before, [data-sort="-8"]::before{
  content: "\2191"
}
</style>
<style>
#form-Freight-list .input-group select {
    border: 1px solid #888;
    background-color: #ddd;
    font-size: .85rem;
}
#form-Freight-list .input-group input {
    border-left: 1px solid #888;
    border-right: 1px solid #888
}
#freight-list-popup .modal-body[data-nofooter="true"]{
    padding: 0
}
#freight-list-popup .col-form-label.col-sm-2{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
</style>